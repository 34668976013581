@import '../base/variables';
@import '../tools/mixins';
@import '../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';


// Page d'accueil

#header-top {
  padding-bottom: 40px;

  .circle {
    top: 0px;
  }
}

#fixed-block-right {
  position: fixed;
  top: 50%;
  margin-top: -28px;
  right: -70px;
  transform: rotate(-90deg);
  z-index: 3;
  width: 195px;
  height: 55px;

  @include media-breakpoint-down(md) {
    top: auto;
    bottom: 0;
    margin-top: 0;
    right: 0;
    left: 0;
    height: 50px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    margin-left: auto;
    margin-right: auto;
  }

  .book {
    float: right;
    height: 55px;
    width: 140px;
    padding-left: 0px;
    padding-right: 0px;

    @include media-breakpoint-down(md) {
      height: 50px;
    }
  }

  .phone {
    float: left;
    padding-left: 0px;
    padding-right: 0px;
    height: 55px;
    width: 55px;
    text-align: center;
    border-right: 2px solid #66583b;

    @include media-breakpoint-down(md) {
      height: 50px;
    }

    img {
      width: 24px;
      height: 24px;
      transform: rotate(90deg);
    }
  }
}

#slider-events-section {
  height: 570px;
  padding-bottom: 60px;

  .wave {
    position: absolute;
    top: -60px;
    width: 100%;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  #list-events {
    position: relative;

    .event {
      position: relative;
      height: 570px;

      @include media-breakpoint-down(md) {
        height: 500px;
      }

      .background-image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .overlay {
        position: absolute;
        width: 50%;
        height: 100%;
        opacity: .8;
        background-image: linear-gradient(to right, $black, transparent);

        @include media-breakpoint-down(md) {
          width: 100%;
          background: $black;
        }
      }

      .content {
        max-width: 410px;
        padding: 40px;

        @include media-breakpoint-down(md) {
          max-width: 100%;
          padding: 15px;
        }

        .date {
          color: $white;
          font-size: 16px;
          text-transform: uppercase;

          @include media-breakpoint-down(md) {
            font-size: 14px;
          }
        }

        .title {
          padding-bottom: 25px;
          padding-top: 15px;
          font-size: 34px;
          line-height: 36px;
          font-weight: bold;
          color: $gold;
          text-transform: uppercase;

          @include media-breakpoint-down(md) {
            font-size: 28px;
            line-height: 30px;
          }

          img {
            display: inline-block;
            width: 26px;
            vertical-align: baseline;

            @include media-breakpoint-down(md) {
              width: 22px;
            }

            &:first-child {
              margin-right: 3px;
            }

            &:last-child {
              margin-left: 3px;
            }
          }
        }

        .description {
          padding-bottom: 35px;

          @include media-breakpoint-down(md) {
            padding-bottom: 50px;
          }
        }

        .button {
          width: 270px;
          max-width: 100%;

          @include media-breakpoint-up(sm) {
            display: block;
          }

          @include media-breakpoint-down(sm) {
            width: 100%;
          }

          &:first-of-type {
            margin-bottom: 20px;
          }
        }
      }
    }

    .owl-stage-outer {
      z-index: 2;
    }

    .owl-nav {
      position: absolute;
      top: 50%;
      margin-top: -19px;
      width: 100%;
      z-index: 2;

      .owl-prev, .owl-next {
        position: absolute;
        height: 38px;
        font-size: 38px;
        color: $gold;
        @include transition;

        @include media-breakpoint-down(md) {
          height: 30px;
          font-size: 30px;
        }

        &.disabled {
          opacity: .2;
        }

        &:not(.disabled) {
          &:hover,
          &:focus,
          &:active {
            color: $light-gold;
          }
        }
      }

      .owl-prev {
        left: -45px;

        @include media-breakpoint-down(md) {
          left: -10px;
        }
      }

      .owl-next {
        right: -45px;

        @include media-breakpoint-down(md) {
          right: -10px;
        }
      }
    }
  }
}

#last-gallery-section {
  padding-top: 100px;
  padding-bottom: 80px;

  @include media-breakpoint-down(md) {
    padding-top: 50px;
  }

  .lights {
    position: absolute;
    left: 0;
    bottom: 0;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  #list-photos {
    position: relative;
    height: 590px;

    @include media-breakpoint-down(md) {
      height: 350px;
    }

    .photo {
      position: relative;
      height: 540px;
      width: 100%;
      border-top: 4px solid $gold;

      @include media-breakpoint-down(md) {
        height: 300px;
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .content {

    @include media-breakpoint-up(lg) {
      margin-top: -50px;
    }

    .title {
      font-size: 26px;
      font-weight: bold;
      line-height: 36px;
      color: $gold;
      text-transform: uppercase;

      @include media-breakpoint-down(md) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    .description {
      margin-top: 35px;
      margin-bottom: 25px;
    }
  }
}


#services-section {
  #list-services {
    .service {
      padding: 90px 35px 110px 35px;
      text-align: center;
      border-top: 1px solid rgb(19, 19, 19);
      border-bottom: 1px solid rgb(19, 19, 19);

      @include media-breakpoint-down(lg) {
        padding: 90px 25px 110px 25px;
      }

      @include media-breakpoint-down(md) {
        padding: 60px 35px 80px 35px;
      }

      .picto {
        display: inline-block;
        width: 60px;
        height: 60px;
      }

      .title {
        font-size: 16px;
        line-height: 40px;
        color: $white;

        @include media-breakpoint-down(lg) {
          line-height: 24px;
        }
      }

      .description {
        color: $white-gold;
        font-size: 16px;
        line-height: 30px;

        @include media-breakpoint-down(lg) {
          line-height: 24px;
        }
      }
    }

    .owl-item:nth-child(even) .service {
      border-left: 1px solid rgb(19, 19, 19);
      border-right: 1px solid rgb(19, 19, 19);
    }

    .owl-dots {
      text-align: center;
      line-height: 50px;

      .owl-dot {
        display: inline-block;
        width: 9px;
        height: 9px;
        margin: 0 5px;
        border: 1px solid #818181;
        vertical-align: middle;

        &.active {
          width: 12px;
          height: 12px;
          background-color: $gold;
          border-color: $gold;
        }
      }
    }
  }
}

#photos-videos-section {
  padding-top: 130px;
  padding-bottom: 200px;
  overflow: hidden;

  @include media-breakpoint-down(md) {
    padding-top: 90px;
    padding-bottom: 100px;
  }

  .wave {
    position: absolute;
    top: 175px;
    width: 100%;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .circle {
    top: -15px;
  }

  .section-title {
    margin-bottom: 40px;
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    color: $gold;

    @include media-breakpoint-down(md) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .album {
    position: relative;
    height: 570px;
    width: 100%;
    margin-bottom: 30px;
    border-top: 4px solid $gold;

    @include media-breakpoint-down(md) {
      height: 270px;
    }

    > .background-image {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: .5;
      //background-image: linear-gradient(to right, $black, transparent);
      background-color: $black;
    }

    .content {
      padding: 60px 20px;

      @include media-breakpoint-down(md) {
        padding: 15px;
      }

      .date {
        color: $white;
        font-size: 20px;
        line-height: 26px;
        text-transform: uppercase;

        @include media-breakpoint-down(xl) {
          font-size: 18px;
          line-height: 24px;
        }

        @include media-breakpoint-down(md) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .title {
        padding-bottom: 20px;
        padding-top: 20px;
        font-size: 50px;
        line-height: 60px;
        font-weight: bold;
        color: $white;
        text-transform: uppercase;

        @include media-breakpoint-down(xl) {
          font-size: 40px;
          line-height: 50px;
        }

        @include media-breakpoint-down(lg) {
          font-size: 32px;
          line-height: 40px;
        }

        @include media-breakpoint-down(md) {
          font-size: 28px;
          line-height: 30px;
        }

        img {
          display: inline-block;
          width: 30px;
          vertical-align: baseline;

          @include media-breakpoint-down(xl) {
            width: 28px;
          }

          @include media-breakpoint-down(lg) {
            width: 26px;
          }

          @include media-breakpoint-down(md) {
            width: 24px;
          }

          &:first-child {
            margin-right: 3px;
          }

          &:last-child {
            margin-left: 3px;
          }
        }
      }

      a {
        color: $white;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: underline;

        &:hover,
        &:focus,
        &:active {
          opacity: .7;
        }
      }

      .see-video-wrapper {
        margin-top: -20px;
        margin-bottom: 10px;
        text-align: center;

        a {
          color: $white;
          font-weight: bold;
          text-transform: uppercase;
          text-decoration: none;

          img {
            height: 60px;
          }

          span {
            display: block;
            margin-top: -10px;
          }
        }
      }
    }
  }

  .column-video {
    .content {
      height: 100%;
    }

    .see-video-wrapper {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .column-photo {
    .album {
      height: 270px;

      > .background-image {
        object-position: top;
      }

      .content {
        padding: 20px 20px;
        top: 50%;
        transform: translateY(-50%);

        @include media-breakpoint-down(md) {
          padding: 15px;
        }
      }
    }
  }

  #link-galleries {
    margin-top: 40px;
  }
}